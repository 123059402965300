.pass-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pass-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pass-input {
    width: 300px;
}
.history-container {
    min-height: 60vh; 
  }

.home-heading h4 {
    margin-top: 5px;
}