.input-group {
    width: 43%;
}

.list-group {
    width: 42%;
}

.list-group-item {
    width: 100%;
}

.d-flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.d-flex-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.discounts-table thead{
    background-color: #141414;
}

.discounts-table th{
    border: none;
    text-align: center;
}

.discounts-table td {
    border: none;
    text-align: center;
}

.cstm-dflex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-input {
    width: 50% !important;
}

.margin-r{
    margin-right: 10px;
}

.remove-btn {
    margin-right: 5px;
}

.d-type {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.wc-100 {
    width: 100px !important;
}
@media (max-width: 767px) {
    .input-group {
        width: 100%;
    }
    
    .list-group {
        width: 100%;
    }
}