.brand-card {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.image-card {
    width: 40%;
}

.image-card img {
    width: 130px;
    border-radius: 5px;
}

.currency-card {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.total-price {
    display: flex;
    flex-direction: column;
}

.products-table {
    min-height: 60vh;
}

.add-to-cart {
    display: flex;
    justify-content: space-between;
}

.brand-col {
    margin-right: 50px;
}

.product-row {
    height: 50px;
}

/* Ensure the dropdown menu has a fixed width and custom scrollbar styles */
.position-relative {
    position: relative;
  }
  
  .dropdown-menu-custom {
    position: absolute;
    background-color: white;
    border: 1px solid #ced4da;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    border-top: none;
  }
  
  /* Custom scrollbar styles */
  .dropdown-menu-custom::-webkit-scrollbar {
    width: 8px;
  }
  
  .dropdown-menu-custom::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .dropdown-menu-custom::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  .dropdown-menu-custom::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .cstm-list li:hover{
    cursor: pointer;
    background-color: #a1a1a1;
  }