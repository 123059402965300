.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .login-box {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-box h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .signup-btn {
    width: 60px;
    background: #820498;
    border: none;
  }
  
  .signup-btn:hover {
    background: #660277;
  }

  .signup-link {
    text-align: center;
    margin-top: 10px;
  }
  
  .signup-link a {
    color: #6f42c1;
    text-decoration: none;
  }

  .cst-input {
    width: 300px;
  }
  