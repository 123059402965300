.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Adjust the z-index as needed */
  }
  
  .confirmation-cart {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 70%;
    z-index: 1001; /* Ensure it's above the overlay */
  }

  .price-div {
    display: flex;
    flex-direction: column;
  }
  .cart-info {
    display: flex;
    justify-content: space-between;
  }

  .total-price-popup {
    display: flex;
    justify-content: space-between;
}
.total-price-popup button {
    width: 100px;
}

.total-price-popup h4 {
    font-size: 20px;
}

/* For small screens (up to 600px) */
@media screen and (max-width: 600px) {
    .popup-overlay {
      align-items: flex-start; /* Adjust alignment for small screens */
    }
  
    .confirmation-cart {
      width: 100%; /* Make the popup wider for small screens */
    }
  
    .total-price button {
      width: auto; /* Allow the button to adjust its width */
    }
  
    .total-price h4 {
      font-size: 18px; /* Reduce font size for small screens */
    }
  }
  
  /* For medium screens (601px to 900px) */
  @media screen and (min-width: 601px) and (max-width: 966px) {
    .confirmation-cart {
      width: 70%; /* Adjust the width for medium screens */
      max-height: 90%; /* Adjust the max height for medium screens */
    }
  }
  
  /* For large screens (901px and above) */
  @media screen and (min-width: 967px) {
    .confirmation-cart {
      width: 70%; /* Adjust the width for large screens */
    }
  }
  
  @media screen and (max-width: 887px) {
    .popup-product-name {
        white-space: nowrap; /* Prevent text from wrapping */
        overflow: hidden; /* Hide the overflowed text */
        text-overflow: ellipsis;
        width: 150px;
        display: block;
    }
  }

  @media screen and (max-width: 687px) {
    .popup-product-name {
        white-space: nowrap; /* Prevent text from wrapping */
        overflow: hidden; /* Hide the overflowed text */
        text-overflow: ellipsis;
        width: 100px;
        display: block;
    }
  }
  /* Additional styles for your other classes can be adjusted similarly. */
  
  .cart-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .cstm-spinner{
    height: 6rem !important;
    width: 6rem !important;
  }