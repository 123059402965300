.topup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
}
.balance-topup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
