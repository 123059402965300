/* Reset default margin and padding for the whole page */
@font-face {
  font-family: poppins500;
  src: url(../src/Assets/Poppins-Medium.ttf);
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Container to fill the entire viewport */
.app-container {
  background: #f1f1f1;
  display: flex;
  transition: 0.4s;
  flex-direction: column;
  min-height: 100vh; /* Ensures the container spans the entire viewport height */
}

/* Main content container */
.main-content {
  flex: 1; /* Takes up available vertical space */
}

/* Footer styling */
.footer {
  flex-shrink: 0; /* Prevents the footer from shrinking */
}


.app-container {
  scrollbar-width: thin !important;
  scrollbar-color: #979595 black !important;
}

/* Webkit-based browsers (Chrome, Safari) */
.app-container::-webkit-scrollbar {
  width: 8px !important;
}

.app-container::-webkit-scrollbar-track {
  background: #222 !important;
}

.app-container::-webkit-scrollbar-thumb {
  background: #ccc !important;
  border-radius: 4px !important;
}

.app-container::-webkit-scrollbar-thumb:hover {
  background: #999 !important;
}

.active-cont {
  width: 85%;
  margin-right: 0;
}
#menu-btn {
  background-color: transparent;
  width: 50px;
  color: black;
}

.balance-box {
  display: flex;
  align-items: center;
}