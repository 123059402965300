.rate-calculator-container {
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .nav-tabs {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .currency-input-group {
    display: flex;
  }
  
  .dropdown {
    margin-right: 20px;
  }
  
  .toggle-switch {
    display: flex;
    align-items: center;
  }
  
  .toggle-switch label {
    margin-left: 10px;
  }
  
  .rate-input {
    width: 300px;
    margin-bottom: 20px;
  }

  .rate-input2 {
    width: 300px;
    margin-bottom: 20px;
    height: 45px;
  }

  .rate-btn {
    width: 150px;
    margin-top: 20px;
    height: 45px;
  }

  .update-btn {
    height: 45px;
  }

  .products-form {
   display: flex;
   flex-direction: column;
  }

  .cart-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .cstm-spinner{
    height: 6rem !important;
    width: 6rem !important;
  }

  .product-checkbox input{
    border: 0.5px solid black;
  }

  .search-container {
    display: flex;
    justify-content: space-between;
  }

  .percentage-switch {
    float: right !important;
  }

  .rate-dropdown-container {
    display: flex;
  }

  .rate-dropdown-container select{
    height: 45px;
    width: 30%;
  }