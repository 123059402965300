.brand-container {
    display: flex;
}

.brand-container img {
    max-width: 60px;
    height: 42px;
    border-radius: 4px;
}
.brand-div-2 {
    display: flex;
    flex-direction: column;
}

.brand-div-2 span {
    font-size: 14px;
}

.brand-div-1 {
    margin-right: 10px;
}

.total-price-cart {
    display: flex;
    justify-content: space-between;
}

.total-price-cart button {
    width: 160px;
}

.total-price-cart h4 {
    font-size: 20px;
}

.cart-checkout-btn {
    height: 40px;
    width: 100px;
}