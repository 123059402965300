.home-container {
    font-family: poppins-500;
    margin-left: 20px;
}
.balance-header {
    position: fixed;
    top: 0px;
    margin-left: 20px;
    width: 80%;
}

.balance-div {
    display: flex;
    float: right;
}

.home-heading {
    display: flex;
    align-items: center;
}
.acc-info-div{
    display: flex;
    width: 100%;
    padding: 10px;
    background-color: white;
    border: 1px solid rgb(239, 223, 223);
}

.api-div {
    width: 300px;
}

.key-div {
    margin-right: 10px;
}
.add-balance-btn {
    border: none;
    margin-right: 10px;
    background-color: #570464;
    color: white;
    border-radius: 4px;
}

@media screen and (max-width: 1277px) {
    .balance-header {
        width: 73%;
    }
  }
  
  @media screen and (max-width: 938px) {
    .balance-header {
        width: 68%;
    }
  }
  
  @media screen and (max-width: 738px) {
    .balance-header {
        width: 63%;
    }
  }

  @media screen and (max-width: 578px) {
    .balance-header {
        width: 58%;
    }
  }

  @media screen and (max-width: 478px) {
    .balance-header {
        width: 50%;
    }
  }