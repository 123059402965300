.side-navbar {
  font-family: poppins-500;
  margin-top: 0;
  width: 0px;
  height: 100%;
  position: fixed;
  margin-left: -300px;
  background: #35023d;
  transition: 0.5s;
}
.nav-link:active,
.nav-link:focus,
.nav-link:hover {
  background-color: #34064126;
}
.my-container {
  transition: 0.4s;
}
.active-nav {
  margin-left: 0;
  width: 13.75rem;
}
/* for main section */
.active-cont {
  margin-left: 13.75rem;
}

.logo-div {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #222;
  margin-bottom: 20px;
}

.mx-2{
  color: rgb(254, 243, 243);
}

.logout-btn {
  background-color: #380441 !important;
  width: 100%;
}

.logout-btn:hover {
  background: #660277;
}

.sidebar-icon {
  color: #b423f3;
}

.profile-bar {
  display: flex;
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
}

.profile-info {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.profile-info span {
  color: white;
}

@media screen and (max-height: 367px) {
  .logo-div {
    display: none;
  }
}