/* SiteUsersInfo.css */
.modal-content {
    width: 400px;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(237 237 237);
    border: 1px solid rgb(172, 172, 172);
  }
  
  .balance-input {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .modal-buttons button {
    padding: 8px;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .modal-balance-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal-balance-div input {
    width: 150px;
  }