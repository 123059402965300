.project-status {
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .project-status.completed {
    color: #28a745;
  }
  
  .project-status.inprogress {
    color: #ffc107;
  }
  
  .project-status.pending {
    color: #007bff;
  }

  .history-container {
    min-height: 60vh; 
  }